globalThis["__sentryRewritesTunnelPath__"] = "/monitoring";
globalThis["SENTRY_RELEASE"] = {"id":"bHKeLMIuPjIrDNIbPcC6r"};
globalThis["__sentryBasePath"] = undefined;
globalThis["__rewriteFramesAssetPrefixPath__"] = "";
// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs'

Sentry.init({
  dsn: 'https://6ce98eb114999bc0aceb7b330461ad8e@o4508174252965888.ingest.us.sentry.io/4508174254342144',

  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
    Sentry.feedbackIntegration({
      colorScheme: 'system',
      lang: 'pt',
    }),
  ],

  tracesSampleRate: 1,

  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  debug: false,
  enabled: process.env.NODE_ENV === 'production',

  beforeSend(event) {
    const ignoredPaths = ['/api/conta', '/api/estatisticas']
    const requestUrl = event.request?.url || ''
    const requestMethod = event.request?.method || ''

    if (
      requestMethod === 'GET' &&
      ignoredPaths.some(path => requestUrl.includes(path))
    ) {
      return null
    }

    return event
  },
})
